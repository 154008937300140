@use 'src/styles/vars.module.scss' as vars;

.root {
  display: inline-flex;
  gap: vars.spacing(1);
  align-items: center;
}

.fullWidth {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
