@use 'src/styles/vars.module.scss' as vars;

.root {
  display: flex;
  align-items: center;
  height: vars.const('headerHeight');
  padding: vars.spacing(2, 0, 10px, 0);
}

.logoWrapper {
  width: vars.const('sidebarWidth');
  padding-left: vars.spacing(3);
  flex-shrink: 0;
}

.logo {
  display: inline-flex;
  align-items: center;
  gap: vars.spacing(1);
  font-size: 0px;
}

.navigation {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: vars.spacing(3);
}

.account {
  display: inline-flex;
  gap: vars.spacing(1);
}
