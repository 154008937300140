@use 'src/styles/vars.module.scss' as vars;

.root {
  width: 100dvw;
  min-width: 100dvw;
  max-width: 100dvw;

  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;

  display: flex;
  flex-direction: column;

  background-color: vars.color('grey.100');
}

.main {
  display: flex;
  flex-grow: 1;
  max-height: calc(100dvh - vars.const('headerHeight'));
}
