* {
  min-width: 0;
}

html, body {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'Roboto';
  src: url('~/src/assets/fonts/roboto-flex.ttf') format('truetype-variations');
  font-weight: 100 1000;
}
